<template>
    <table class="contenu">
        <thead class="tetiere">
        <tr>
            <th class="text-center col-vignette">Article</th>
            <th class="text-center">Quantité</th>
            <th class="text-right">Prix HT</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="element in commande.contenu"
            :key="element.name"
        >
            <td class="articles">
                <div class="col-vignette">
                    <div class="vignetteif">
                        <img-portail :dist-url="getVignette(element)" :titre="element.titre"></img-portail>
                    </div>
                </div>
                <span class="metas">{{ element.titre }}</span>
            </td>
            <td class="quantites text-center">{{ element.quantite }} ex.</td>
            <td class="prix text-right">{{ formatMonetaire(element.prixunitaire) }}</td>
        </tr>
        <template v-if="commande.facturation">
            <td rowspan="9" class="actions">
                <!-- Rowspan de 9 pour qu'il prenne tout, tant qu'il peut -->
                <div v-if="commande.facturation && commande.facturation.numero_facture">
                    <btn-theme @click="getFacturePDF(commande.facturation.id)">Télécharger la facture</btn-theme>
                </div>
                <div v-if="detail">
                    <router-link  custom :to="'/commande/'+ commande.id" v-slot="{ navigate }">
                        <btn-theme @click="navigate">Détail de la commande</btn-theme>
                    </router-link>
                </div>
                <div v-if="commande.suivi">
                    <btn-theme :href="commande.suivi" target="_blank">Suivre mon envoi</btn-theme>
                </div>
            </td>
            <tr>
                <template v-if="commande.facturation.transportht">
                    <td class="right">Frais de livraison HT</td>
                    <td class="right">{{ formatMonetaire(commande.facturation.transportht) }}</td>
                </template>
            </tr>
            <tr v-if="commande.facturation.gestionht">
                <td class="right">Frais de gestion HT</td>
                <td class="right">{{ formatMonetaire(commande.facturation.gestionht) }}</td>
            </tr>
            <tr v-if="commande.facturation.remise">
                <td class="right">Remise HT (-{{commande.facturation.remise.valeur}}{{commande.facturation.remise.type}})</td>
                <td class="right">-{{ formatMonetaire(commande.facturation.remise.total) }}</td>
            </tr>
            <tr v-if="commande.facturation.totalttc">
                <td class="right">Total de la commande TTC</td>
                <td class="right">{{ formatMonetaire(commande.facturation.totalttc) }}</td>
            </tr>
            <tr v-if="commande.facturation.totaltva">
                <td class="right tva" colspan="2">(dont TVA : {{ formatMonetaire(commande.facturation.totaltva) }})</td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
import BtnTheme from "./theme/BtnTheme";
import ImgPortail from "./theme/ImgPortail";
import monnaieMixin from "../mixins/monnaie";
import Api from "../api/api";

export default {
    name: "CommandeInfos",
    components: {BtnTheme, ImgPortail},
    mixins: [
        monnaieMixin
    ],
    props: {
        commande: {
            type: Object,
            required: true
        },
        detail: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            urlVignette:    process.env.VUE_APP_API_URL + '/image/vignette/grande',
            urlFacturePDF:  process.env.VUE_APP_API_URL + '/facturation/pdf',
        }
    },
    methods: {
        getVignette(element) {
            return this.urlVignette + '/' + encodeURI(element.vignette) + '?libelle=' + encodeURI(element.reference);
        },
        getFacturePDF(factureId) {
            Api.downloadResponseFile(
                this.urlFacturePDF +'/'+ factureId,
                'Facture_'+ factureId +'.pdf',
                null,
                'GET');
        }
    }
}
</script>

<style scoped lang="scss">
    .commande {
        & .titre {
            font: normal normal normal 35px/47px Nunito;
        }
        & .tetiere {
            font: italic normal normal 25px/34px Nunito;
            color: #808080;
        }
        & .contenu {
            width:100%;
            & .actions {
                & div {
                    margin-top:4px;
                }
            }
            & .articles {
                display:flex;
                flex-direction:row;
                & .metas {
                    min-width:400px;
                    margin:auto;
                    text-align:center;
                }
            }
            & > * {
                .tva {
                    font: normal normal normal 20px/27px Nunito;
                }
                font: normal normal normal 25px/34px Nunito;
            }
        }
    }
</style>