<template>
    <div class="commandes">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/compte">Mon compte</router-link>
                <span> > </span>
                <router-link to="/commandes">Historique de Commandes</router-link>
            </div>
            <h1 class="titre">Historique de Commandes</h1>
        </div>
        <div class="carte-arrondie" v-if="commandesVisibles.length === 0">
            <h3 class="mx-auto py-6">Vos commandes passées sur Imprime Facile apparaîtront ici</h3>
        </div>
        <div class="carte-arrondie" v-else>
            <div class="commande" v-for="commande in commandesVisibles" :key="commande.id">
                <h1 class="titre">
                    Commande du {{ commande.datecommande }} n°{{ commande.id }}
                    Etat : {{ commande.etat }}
                </h1>
                <commande-infos :commande="commande"></commande-infos>
                <v-divider></v-divider>
            </div>
            <div class="text-center">
                <v-pagination
                    v-model="page"
                    :length="Math.ceil(this.commandes.length/this.parPage)"
                ></v-pagination>
            </div>
        </div>
    </div>
</template>
<script>

import _ from 'lodash';
import Api from '../api/api';
import DateConversionMixin from '../mixins/conversion/date';
import UserMixin from '../mixins/user';
import StatutMixin, {LISTE_STATUTS} from '../mixins/statuts';
import {mapGetters} from "vuex";
import CommandeInfos from "../components/CommandeInfos";

export default {
    name: "Commandes",
    components: {CommandeInfos},
    mixins: [
        DateConversionMixin,
        UserMixin,
        StatutMixin
    ],
    data: () => ({
        getPeriodesURL :        process.env.VUE_APP_API_URL + '/commande/periodes',
        getCommandesURL:        process.env.VUE_APP_API_URL + '/commande/detaillees',
        postEffaceCommandeURL:  process.env.VUE_APP_API_URL + '/commande/efface',
        periode: '',
        statut: '',
        listeStatuts:LISTE_STATUTS,
        recherche: '',
        listePeriodes: [],
        commandes    : [],
        entetes      : [],
        headers: [
            { text: 'N° de commande', align: 'center', value: 'id' },
            { text: 'Réf.', align: 'left', value: 'reference' },
            { text: 'Destinataire', value: 'destinataire' },
            { text: 'Date de commande', value: 'datecommandeformatee' },
            { text: 'Statut de la commande', value: 'etat' },
            { text: '', align: 'right', value: 'actions' }
        ],
        chargementEnCours: false,
        page: 1,
        parPage: 5,
        nombreDePages: 0,
        elementsParPage: 10,
    }),
    computed: {
        ...mapGetters([
            'user'
        ]),
        filtreStatut:function (){
            if (this.statut === "" && this.statut !== 0) {
                return this.commandes;
            }else{
                return this.commandes.filter((element)=> {
                    return element['etat'].valeur === this.statut
                })
            }
        },
        commandesVisibles: function() {
            return this.commandes.slice((this.page - 1)* this.parPage, this.page* this.parPage)
        }
    },
    watch: {
        page (page) {
            if (!this.$route.query.page || parseInt(this.$route.query.page) !== page || isNaN(page)) this.$router.push({query: {page: page}})
        }
    },
    methods: {
        getCommandes () {
            this.chargementEnCours = true;
            return Api.get(this.getCommandesURL)
                .then(commandes => {
                    this.commandes = _.chain(commandes)
                        .sortBy('id')
                        .reverse()
                        .value();
                    this.chargementEnCours = false;
                });
        },
        restorePage () {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        },
        traiteErreurs(err) {
            let msg = Api.getMessageFrom(err);
            if (msg) this.$store.commit('addSnackMessage', {msg: msg});
        },
        afficheListeStatut(){
            this.listeStatuts = this.listeStatuts.map(o => ({value: o.code, text: o.libelle}));
            this.listeStatuts.push({value: '', text: 'Tout'});
        },
    },
    mounted () {
        this.getCommandes()
            .then(this.restorePage)
            .then(this.afficheListeStatut)
            .catch(this.traiteErreur);
    }
}

</script>
<style scoped lang="scss">
.commandes {
    & .carte-arrondie {
        display:flex;
        flex-direction:column;
        padding:0 45px 20px 45px;
        & .commande {
            margin-top:25px;
            & .v-divider {
                margin-top:20px;
            }
        }
    }
}
</style>
