
export default {
    methods: {
        UTCtoDateTime (dateUTC) {
            return new Date(dateUTC).toLocaleString();
        },
        UTCtoDate (dateUTC) {
            return new Date(dateUTC).toLocaleDateString();
        },
        UTCtoTime (dateUTC) {
            return new Date(dateUTC).toLocaleTimeString();
        },
        UTCtoTimestamp (dateUTC) {
            return new Date(dateUTC).getTime();
        }
    }
}