<template>
<v-btn dark :color="themecolor" v-bind="$attrs" v-on="$listeners" class="zi-2">
    <slot></slot>
</v-btn>
</template>

<script>
import ThemeMixin from './theme';
export default {
    name: "BtnTheme",
    mixins: [
        ThemeMixin
    ],
}
</script>

<style scoped>
.zi-2 {
    z-index: 2;
 }
</style>