<template>
    <img :src="dataUrl" decoding="async" alt="" v-bind="$attrs" v-on="$listeners" />
</template>
<script>
import ImageMixin from './image';
export default {
    name: "ImgPortail",
    mixins: [
        ImageMixin
    ],
    mounted() {
    }
}
</script>
