export const STATUT_ATTENTE        =   0;
export const STATUT_EN_TRAITEMENT  =   1;
export const STATUT_EXPEDIE        =   2;
export const STATUT_EN_EXPEDITION  =   3;
export const STATUT_NON_PAYE       =   4;
export const STATUT_EN_APPROBATION =   5;
export const STATUT_BLOQUE         =   16;
export const STATUT_TRAITE_PARTIEL =   17;
export const STATUT_EXPEDIE_PARTIEL=   18;
export const STATUT_NPAI           =   20;
export const STATUT_NON_RECLAME    =   21;
export const STATUT_REFUSE         =   22;
export const EFFACABLE = [
    STATUT_ATTENTE,
    STATUT_NON_PAYE,
    STATUT_EN_APPROBATION,
    STATUT_BLOQUE
];
export const NON_VALIDEE = [
    STATUT_NON_PAYE,
    STATUT_EN_APPROBATION,
    STATUT_BLOQUE
];
export const TRAITE = [
    STATUT_EXPEDIE,
    STATUT_EXPEDIE_PARTIEL,
    STATUT_NPAI,
    STATUT_NON_RECLAME,
    STATUT_REFUSE
];

export const STATUT_TEXTE = {
    0: 'En attente de traitement',
    1: 'En traitement',
    2: 'Expédié',
    3: 'En expédition',
    4: 'En attente de paiement',
    5: 'En attente d\'approbation',
    16: 'Bloqué',
    17: 'Traité partiellement',
    18: 'Expédié partiellement',
    20: 'Retourné (Erreur d\'adresse)',
    21: 'Retourné (Colis non réclamé)',
    22: 'Retourné (Colis refusé)'
}
export const LISTE_STATUTS = [
    { code: 0, libelle: 'En attente de traitement' },
    { code: 1, libelle: 'En traitement' },
    { code: 2, libelle: 'Expédié' },
    { code: 3, libelle: 'En expédition' },
    { code: 4, libelle: 'En attente de paiement' },
    { code: 5, libelle: 'En attente d\'approbation' },
    { code: 16, libelle: 'Bloqué' },
    { code: 17, libelle: 'Traité partiellement' },
    { code: 18, libelle: 'Expédié partiellement' },
    { code: 20, libelle: 'Retourné (Erreur d\'adresse)' },
    { code: 21, libelle: 'Retourné (Colis non réclamé)' },
    { code: 22, libelle: 'Retourné (Colis refusé)' }
];
export const STATUT_COULEUR_FOND = {
    0: 'gray',
    1: 'blue',
    2: 'green',
    3: 'gray',
    4: 'yellow',
    5: 'yellow',
    16: 'black',
    17: 'orange',
    18: 'orange',
    20: 'red',
    21: 'red',
    22: 'red'
}
export const STATUT_COULEUR_TEXTE = {
    0: 'black',
    1: 'white',
    2: 'white',
    3: 'black',
    4: 'black',
    5: 'black',
    16: 'white',
    17: 'white',
    18: 'white',
    20: 'white',
    21: 'white',
    22: 'white'
}
export default {
    methods: {
        statutEnTexte(code) {
            return STATUT_TEXTE[code];
        },
        statutCouleurFond(code) {
            return STATUT_COULEUR_FOND[code];
        },
        statutCouleurTexte(code) {
            return STATUT_COULEUR_TEXTE[code];
        },

    }
}
